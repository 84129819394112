import api from './api'

const url = 'suggestion'

const postSuggestion = params => api.post(url, params)
const postSuggestionExternal = params => api.post(`${url}/active-external`, params)

const getSuggestion = (user) => api.get(`${url}/user?name=${user}`);

const findStatusNotRead = () => api.get(`${url}/status`);

const updateReadStatus = (id) => api.get(`${url}/status-update?id=${id}`);

export default {
  postSuggestion, postSuggestionExternal, getSuggestion, findStatusNotRead, updateReadStatus
}
